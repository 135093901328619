.envelope {
  position: relative;
  width: 200px;
  height: 150px;
  perspective: 1000px;
}

.front, .back, .flap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: red;
}

.front {
  z-index: 3;
}

.back {
  z-index: 2;
  transform: rotateY(180deg);
}

.flap {
  z-index: 1;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}
.bgc {

  background-color: #070026;
  }

.envelope.open .flap {
  transform: rotateY(-180deg);
}

.content {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background-color: white;
  overflow: auto;
  padding: 10px;
  display: none;
}

.envelope.open .content {
  display: block;
}

.letter {
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.middle {
  width:  100%;
  height: auto;
  margin: 0 auto; /* 将元素水平居中对齐 */
  display: flex;
  flex-direction: column; /* 将子元素垂直排列 */
  justify-content: center; /* 在容器中垂直居中子元素 */
background-color: #140d0d;

}

.middle2 {
  width:  1200px;
  height: auto;
  margin: 0 auto; /* 将元素水平居中对齐 */
  display: flex;
  flex-direction: column; /* 将子元素垂直排列 */
  justify-content: center; /* 在容器中垂直居中子元素 */
background-color: #ffffff;

  }
