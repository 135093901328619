@import '~antd/dist/antd.css';
.site-card-border-less-wrapper {
    padding: 30px;
  }
.card {
  height: 100%;
  width: 100%;


  }
  .middle {
    width:  100%;
    height: auto;
    margin: 0 auto; /* 将元素水平居中对齐 */
    display: flex;
    flex-direction: column; /* 将子元素垂直排列 */
    justify-content: center; /* 在容器中垂直居中子元素 */
  background-color: #ffffff;
  
    }
    .middle2 {
      width:  1624px;
      height: 1000px;
      /*margin: 0 auto; /* 将元素水平居中对齐 */
      /*display: flex;
      /*flex-direction: column; /* 将子元素垂直排列 */
      /*justify-content: center; /* 在容器中垂直居中子元素 */
    background-color: #ffffff;
    
      }
@font-face {
  font-family: 'F1';
  src: url('/src/front/F1.ttf') format('truetype');
  /* 如果你有其他格式的字体文件，可以使用类似的方式引入 */
}
@font-face {
  font-family: 'F2';
  src: url('/src/front/F2.ttf') format('truetype');
  /* 如果你有其他格式的字体文件，可以使用类似的方式引入 */
}
@font-face {
  font-family: 'F3';
  src: url('/src/front/F3.ttf') format('truetype');
  /* 如果你有其他格式的字体文件，可以使用类似的方式引入 */
}
@font-face {
  font-family: 'F4';
  src: url('/src/front/F4.ttf') format('truetype');
  /* 如果你有其他格式的字体文件，可以使用类似的方式引入 */
}