.ncentera{
  text-align: center;
  background-color: #070026;
  justify-Content:'space-between';
  text-align: justify;
  font-size: large;
}
.row{
  padding: 8px 0;
}
.background {
  background-image: url('/src/imgs/Designer.png');
  /* 可选：设置背景图片的大小和重复方式 */
  background-size: cover; /* 调整图片大小以覆盖整个元素 */
  background-repeat: no-repeat; /* 不重复显示背景图片 */
  /* 可选：设置背景图片的位置 */
  background-position: center; /* 将背景图片置于元素中心 */
}
